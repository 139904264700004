@-webkit-keyframes bounce-left {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-30px);
  }
  60% {
    -webkit-transform: translateX(-15px);
  }
}

@-moz-keyframes bounce-left {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateX(0);
  }
  40% {
    -moz-transform: translateX(-30px);
  }
  60% {
    -moz-transform: translateX(-15px);
  }
}

@-o-keyframes bounce-left {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateX(0);
  }
  40% {
    -o-transform: translateX(-30px);
  }
  60% {
    -o-transform: translateX(-15px);
  }
}
@keyframes bounce-left {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
}

.bounce-left {
  animation: bounce-left 2s infinite;
  -webkit-animation: bounce-left 2s infinite;
  -moz-animation: bounce-left 2s infinite;
  -o-animation: bounce-left 2s infinite;
}
