/**
 * Style and Look-And-Feel override for Material UI components should be changed in the default.ts file.
 * Please refer to https://material-ui.com/customization/theming/ on how to approach that.
 * Use this stylesheet to override styles that are not achievable with createMuiTheme provider only.
 **/

.MuiRadio-root,
.MuiCheckbox-root {
  &:hover {
    background: rgba(113, 113, 113, 0.08) !important;
    // svg {
    //   filter: drop-shadow(0 5px 5px gray) !important;
    // }
  }
}
