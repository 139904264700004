/** Animations **/
@use './styles/animations/bounce.scss';

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: visible;
}

/* WebKit auto-fill  */
input:-webkit-autofill {
  transition: all 0s 50000s;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;

  &.modal-active {
    &:before {
      content: '';
      background: rgba(0, 0, 0, 0.3);
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
}

.MuiOutlinedInput-root {
  //background: #fff !important;
  //font-weight: 300;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: #fff;
  padding: 0 5px;
}

td.MuiTableCell-body {
  position: relative;
}

.black-white {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

@media (max-width: 1023px) {
  body,
  .table-container {
    -webkit-overflow-scrolling: touch !important;
  }

  body.menu-docked {
    overflow: hidden;
  }

  table {
    overflow: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  header {
    &.dashboard-header {
      position: fixed !important;
    }
  }

  main {
    min-height: 100vh;
    height: 100% !important;
  }

  .responsive-container-scroll {
    .recharts-wrapper {
      overflow-x: scroll;
      width: 50% !important;
    }
  }
}
